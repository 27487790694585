/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/prefer-default-export */
import "./src/styles/global.css";


// trigger an immediate page refresh when an update is found
export const onServiceWorkerUpdateReady = ()  => window.location.reload();



